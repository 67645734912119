import React, { useRef, useEffect, useState } from "react";
import { Input } from "antd";
import "react-multi-carousel/lib/styles.css";
// import thumbnail from "../Assets/thumbnail.jpg";
import AudioPlayer from "react-h5-audio-player";
import { FaSearch } from "react-icons/fa";
import MusicCarousel from "../Components/MusicCarousel";
// import { MusicData1, MusicData2, MusicData3 } from "../Music";
import { useDispatch, useSelector } from "react-redux";
import { showMusicPlayer, musicDetails } from "../Redux/Actions/MusicActions";
import { db } from "../config";
import { css } from "@emotion/react";
import CircleLoader from "react-spinners/CircleLoader";

const Music = () => {
  const audioPlayerRef = useRef();
  const dispatch = useDispatch();
  const musicInfo = useSelector((state) => state.MusicReducer.musicDetails);
  // const playingId = useSelector((state) => state.MusicReducer.playingId);
  const musicPlayer = useSelector((state) => state.MusicReducer.musicPLayer);

  const [musicData, setMusicData] = useState(null);
  const [musicArray, setMusicArray] = useState([]);
  const [searchMusicInp, setSearchMusicInp] = useState("");

  const [currentSong, setCurrentSong] = useState("");

  useEffect(() => {
    // console.log(playingId)

    // console.log(audioPlayerRef.current.audio.current);
    if (musicInfo.id === null) {
      return;
    }

    if (musicInfo.id && musicInfo.playing) {
      // console.log('if');
      // playing audio
      audioPlayerRef.current.audio.current.play();
      // setting random time for audio to play
      if (musicInfo.id !== currentSong) {
        setCurrentSong(musicInfo.id);
        let range = Math.floor(Math.random() * 100) + 1;
        audioPlayerRef.current.audio.current.currentTime = range;
      }
    } else if (musicInfo.id && !musicInfo.playing) {
      // console.log('else');
      audioPlayerRef.current.audio.current.pause();
    }
  }, [musicInfo]);

  // firebase

  useEffect(() => {
    const collections = [];
    const getCollections = async () => {
      const snapshot = await db.collection("Music").get();
      snapshot.docs.map(async (doc) => {
        await collections.push({ name: doc.data().name, id: doc.id });
      });
      setMusicArray([...collections]);
    };
    getCollections();
  }, []);

  useEffect(() => {
    const fullData = [];
    musicArray.map(async (item) => {
      let tempArr = [];
      const snapshot = await db
        .collection("Music")
        .doc(item.id)
        .collection("items")
        .get();
      snapshot.docs.map(async (doc) => {
        tempArr = [
          ...tempArr,
          {
            name: doc.data().name,
            id: doc.id,
            cover: doc.data().cover,
            musicSrc: doc.data().musicSrc,
          },
        ];
      });
      await fullData.push({
        name: item.name,
        id: item.id,
        items: [...tempArr],
      });

      if (fullData.length === musicArray.length) {
        // console.log(fullData);
        setMusicData([...fullData]);
      }
    });
  }, [musicArray]);
  console.log(musicData);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: #ff4081;
  `;

  const searchMusic = (value) => {
    if (value === "") {
      setMusicData(musicData);
      // setSearchMusicInp("");
    } else setSearchMusicInp(value);
    const tempArr = [];
    musicData.map((data) => {
      const arr = data.items.filter((item) => {
        return item.name.toLowerCase().includes(searchMusicInp.toLowerCase());
      });
      const obj = {
        ...data,
        items: arr,
      };
      tempArr.push(obj);
    });
    setMusicData(tempArr);
  };

  return (
    <div>
      <div className="music-container">
        <div className="btn-container">
          <Input
            prefix={<FaSearch />}
            placeholder="search music"
            className="action-btn genre"
            style={{ background: "transparent", color: "#fff" }}
            allowClear
            value={searchMusicInp}
            onChange={(e) => searchMusic(e.target.value)}
          />
          {/* <Button onClick={searchMusic} className="action-btn location">
            Search
          </Button> */}
        </div>
        {musicData ? (
          <>
            {musicData.map((song, i) => {
              return (
                <div className="music-carousel" key={i}>
                  <MusicCarousel name={song.name} MusicData={song.items} />
                </div>
              );
            })}
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircleLoader color={"pink"} css={override} size={150} />
          </div>
        )}
      </div>
      <div
        style={{
          position: "fixed",
          bottom: -20,
          zIndex: 10000,
          fontSize: "2em",
          left: 10,
          background: "#000000",
          display: "flex",
          color: "#fff",
          alignItems: "center",
          marginTop: "50px",
          visibility: !musicPlayer ? "hidden" : "visible",
        }}
        className="player-content"
      >
        <img
          src={musicInfo.cover}
          height="50px"
          width="50px"
          style={{
            borderRadius: "50%",
            marginRight: "10px",
            marginBottom: "25px",
          }}
          alt="logo"
          className={musicInfo.playing ? "player-image" : ""}
        />
        <p>{musicInfo.name}</p>
      </div>
      <AudioPlayer
        ref={audioPlayerRef}
        loop={true}
        onPlay={() => {
          if (!musicPlayer) {
            dispatch(showMusicPlayer(true));
          } else {
            dispatch(
              musicDetails({
                name: musicInfo.name,
                musicSrc: musicInfo.musicSrc,
                id: musicInfo.id,
                playing: true,
                cover: musicInfo.cover,
              })
            );
          }
        }}
        onPause={() => {
          dispatch(
            musicDetails({
              name: musicInfo.name,
              musicSrc: musicInfo.musicSrc,
              id: musicInfo.id,
              playing: false,
              cover: musicInfo.cover,
            })
          );
        }}
        style={{
          position: "fixed",
          bottom: 0,
          background: "#000000",
          visibility: !musicPlayer ? "hidden" : "visible",
        }}
        src={musicInfo.id ? musicInfo.musicSrc : ""}
      />
    </div>
  );
};

export default Music;
