/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import thumbnail from "../Assets/thumbnail.jpg";
import { FaPause, FaPlay } from "react-icons/fa";
import { Button, Typography, Modal, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { musicDetails, updateMusicId } from "../Redux";
import { useState } from "react";

const MusicCard = ({ music, index }) => {
  const dispatch = useDispatch();
  const { Title } = Typography;
  const musicInfo = useSelector((state) => state.MusicReducer.musicDetails);
  const musicPlayer = useSelector((state) => state.MusicReducer.musicPLayer);
  const playing = useSelector(
    (state) => state.MusicReducer.musicDetails.playing
  );

  const [togglePlaying, setTogglePlaying] = useState(true);
  const [visible, setVisible] = useState(false);
  const [key, setKey] = useState("");

  const authenticateKey = () => {
    if (key === "000") {
      console.log("playing");
      dispatch(
        musicDetails({
          name: music.name,
          musicSrc: music.musicSrc,
          id: music.id,
          playing: musicInfo.id === music.id ? !playing : true,
          cover: music && music.cover,
        })
      );
      setVisible(false);
      // setTogglePlaying(!togglePlaying);
    } else {
      message.error({
        content: "Incorrect Key",
        duration: 0.7,
      });
    }
  };

  return (
    <>
      <div
        className={"carousel-container"}
        onClick={() => {
          if (key === "") {
            setVisible(!visible);
          } else {
            authenticateKey();
          }
        }}
      >
        {" "}
        <div>{/* <PlayMusicModal /> */}</div>
        <div>
          {musicPlayer && musicInfo && music.id === musicInfo.id && (
            <div
              className={"thumbnail"}
              style={{
                backgroundColor: "black",
                position: "absolute",
                height: "200px",
                width: "200px",
                opacity: "0.7",
              }}
            >
              {playing ? (
                <FaPause className="play-pause " />
              ) : (
                <FaPlay className="play-pause " />
              )}
            </div>
          )}
          <img src={music.cover} alt="music" className="thumbnail" />
        </div>
        <Title level={3}>{music.name}</Title>
      </div>
      <Modal
        visible={visible}
        title="Music Key"
        maskClosable={false}
        // onOk={this.handleOk}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Close
          </Button>,
          <Button key="submit" onClick={authenticateKey}>
            Submit
          </Button>,
        ]}
      >
        <div>
          <Title level={5}>Enter Key</Title>
          <Input value={key} onChange={(e) => setKey(e.target.value)} />
        </div>
      </Modal>
    </>
  );
};

export default MusicCard;
