import React from "react";
import logo from "../Assets/vLogo.png";
import { FaYoutube, FaInstagram, FaLinkedinIn } from "react-icons/fa";
const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer">
        <div>
          <img src={logo} alt="log" className="footer-logo" />
        </div>
        <div className="footer-icons-container">
          <FaYoutube fontSize={30} className="footer-icons" />
          <FaInstagram fontSize={30} className="footer-icons" />
          <FaLinkedinIn fontSize={30} className="footer-icons" />
        </div>
      </div>
      <div>
        <p>Copyright © 2021 ·VIVECA MEDIA</p>
      </div>
    </div>
  );
};

export default Footer;
