import React from "react";
import Content from "../Components/Content";
import Footer from "../Components/Footer";

const Home = () => {
  return (
    <div>
      <Content />
      <Footer />
    </div>
  );
};

export default Home;
