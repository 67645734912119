import * as MusicTypes from "../Types/MusicTypes";

export const showMusicPlayer = (payload) => async (dispatch) => {
  // console.log(payload);
  try {
    // if (payload) {
    dispatch({
      type: MusicTypes.TOGGLE_PLAYER,
      payload: payload,
    });
    // }
  } catch (err) {
    // console.log(err);
  }
};
export const updateMusicId = (payload) => async (dispatch) => {
  // console.log(payload);

  // if (payload) {
  dispatch({
    type: MusicTypes.UPDATE_MUSIC_ID,
    payload: payload,
  });
  // }
};

export const toggleVisibility = (payload) => async (dispatch) => {
  // console.log(payload);

  // if (payload) {
  dispatch({
    type: MusicTypes.VISIBILITY,
    payload: payload,
  });
  // }
};
export const musicDetails = (payload) => async (dispatch) => {
  // console.log(payload);

  //   if (payload) {
  dispatch({
    type: MusicTypes.MUSIC_DETAILS,
    payload: payload,
  });
  //   }
};

export const updatePlaylist = (payload) => async (dispatch) => {
  //   if (payload) {
  dispatch({
    type: MusicTypes.PLAYLIST_UPDATE,
    payload: payload,
  });
  //   }
};
