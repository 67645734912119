import * as MusicTypes from "../Types/MusicTypes";
const initialState = {
  musicPLayer: false,
  playingId: null,
  musicType: "",
  visible: false,
  musicDetails: {
    name: "",
    musicSrc: "",
    id: null,
    volume: null,
    playing: null,
    cover: null,
  },
  playlist: [],
};

const MusicReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case MusicTypes.TOGGLE_PLAYER:
      return {
        ...state,
        musicPLayer: payload,
      };
    case MusicTypes.UPDATE_MUSIC_ID:
      return {
        ...state,
        playingId: payload,
      };
    case MusicTypes.VISIBILITY:
      return {
        ...state,
        visible: payload,
      };
    case MusicTypes.MUSIC_DETAILS:
      return {
        ...state,
        musicDetails: payload,
      };
    case MusicTypes.PLAYLIST_UPDATE:
      return {
        ...state,
        playlist: payload,
      };

    default:
      return state;
  }
};

export default MusicReducer;
