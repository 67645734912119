import React from "react";
import { Row, Col } from "antd";
import TextTransition, { presets } from "react-text-transition";
import { useEffect, useState } from "react";
import { Typography, Button } from "antd";
// import { FaRegSmile } from "react-icons/fa";
// import { MdRestaurant } from "react-icons/md";
// import { BsTagFill } from "react-icons/bs";
// import { ImHourGlass } from "react-icons/im";
// import { GiNightSleep } from "react-icons/gi";
import { BiCustomize } from "react-icons/bi";
import { FaMusic, FaTelegramPlane, FaSlidersH } from "react-icons/fa";

import logo1 from "../Assets/1.png";
import logo2 from "../Assets/2.png";
import logo3 from "../Assets/3.png";
import logo4 from "../Assets/4.png";
import logo5 from "../Assets/5.png";

const TEXTS = ["RELAX", "SMILE", "STAY", "EAT", "BUY"];

const Content = () => {
  const { Title, Text } = Typography;
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);
  return (
    <div>
      <div className="content-container">
        <Row>
          <Col xs={24} lg={24}>
            <Col xs={24} lg={13} xl={12}>
              <div className="header-content">
                <Title level={1} className="header-content-text">
                  MUSIC MAKES YOU
                  <Text className="fade">
                    <TextTransition
                      text={TEXTS[index % TEXTS.length]}
                      springConfig={presets.wobbly}
                    />
                  </Text>
                </Title>
              </div>
            </Col>
            <Col xs={24}>
              <div className="content-icons-container">
                {/* <ImHourGlass className="each-icon" />
                <MdRestaurant className="each-icon" />
                <BsTagFill className="each-icon" />
                <GiNightSleep className="each-icon" />
                <FaRegSmile className="each-icon" /> */}
                <img src={logo1} alt="logo" className="each-icon" />
                <img src={logo2} alt="logo" className="each-icon" />
                <img src={logo3} alt="logo" className="each-icon" />
                <img src={logo4} alt="logo" className="each-icon" />
                <img src={logo5} alt="logo" className="each-icon" />
              </div>
            </Col>
          </Col>
        </Row>
        <Row style={{ marginTop: "100px" }}>
          <Col xs={24} lg={12}>
            <div className="content-categories">
              <FaMusic className="ccg-icons" />
              <Title level={1}>Music</Title>

              <p>
                Branded customer experience starts here. Capture the emotional
                power of music for business and put it to work at your location
              </p>
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <div className="content-categories">
              <FaTelegramPlane className="ccg-icons" />
              <Title level={1}>Messaging</Title>
              <p>
                Capturing your brand voice is essential to your business.
                Transform audio messaging into a powerful part of your customer
                experience.
              </p>
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <div className="content-categories">
              <FaSlidersH className="ccg-icons" />
              <Title level={1}>Mix It</Title>
              <p>
                Mix with targeted messages with the right music to communicate
                with your clients. Create a branded channel and impact decisions
                at the point-of-purchase.
              </p>
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <div className="content-categories">
              <BiCustomize className="ccg-icons" />
              <Title level={1}>Customize</Title>
              <p>
                Our professional Music Designer helps you to develop your
                personalized soundtrack. Create a consistent experience in each
                of your locations around the globe.
              </p>
            </div>
          </Col>
        </Row>
      </div>

      <Row style={{ marginTop: "40px" }}>
        <Col xs={24} lg={12} xxl={12}>
          <div className="emulator">
            <iframe
              // eslint-disable-next-line react/no-unknown-property
              src="https://appetize.io/embed/hmav0tr9kywhyh39fyftabmbtc?device=iphone6&scale=75&autoplay=true&orientation=portrait&deviceColor=black"
              width="312px"
              height="653px"
              // eslint-disable-next-line react/no-unknown-property
              frameBorder="0"
              scrolling="no"
            />
          </div>
        </Col>
        <Col xs={24} lg={12} xxl={12}>
          <div className="contact-container">
            <Title level={1}>Get It Today!</Title>
            <p className="para">
              No matter what field Your business is operating on, we can help
              create a unique music profile for You
            </p>
            <Button
              href="mailto:info@vivecamedia.com"
              // type="primary"
              className="contact-btn"
            >
              Contact
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Content;
