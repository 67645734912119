import React from "react";
import logo from "../Assets/vLogo.png";

const Navbar = () => {
  return (
    <div>
      <div className="main-header">
        <div className="header">
          <div className="header-content">
            <img src={logo} alt="logo" className="header-logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
