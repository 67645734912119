import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyAAYBHEusvLBv-IGX6hVbn0emGbhWs8DdY",
  authDomain: "vivecamediaweb.firebaseapp.com",
  projectId: "vivecamediaweb",
  storageBucket: "vivecamediaweb.appspot.com",
  messagingSenderId: "294890711417",
  appId: "1:294890711417:web:9e61d5559fd8ea5ecaa7a8",
};

const app = firebase.initializeApp(firebaseConfig);

const db = app.firestore();

export { db };
