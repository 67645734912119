import { Typography, Row, Col, Button, Collapse } from "antd";
import TextTransition, { presets } from "react-text-transition";
import { useEffect, useState } from "react";
import {
  FaArrowAltCircleRight,
  FaMusic,
  FaTelegramPlane,
  FaSlidersH,
  FaPlay,
} from "react-icons/fa";
import { BiCustomize } from "react-icons/bi";
import { BsPersonDash } from "react-icons/bs";
import Footer from "../Components/Footer";

import logo1 from "../Assets/1.png";
import logo2 from "../Assets/2.png";
import logo3 from "../Assets/3.png";
import logo4 from "../Assets/4.png";
import logo5 from "../Assets/5.png";
import PlayerImg from "../Assets/music-player.png";
import waves from "../Assets/waves.png";
import happy from "../Assets/images.jpg";

const TEXTS = ["RELAX", "SMILE", "STAY", "EAT", "BUY"];

const Blockchain = () => {
  const [index, setIndex] = useState(0);
  const { Panel } = Collapse;
  const { Title, Text } = Typography;
  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <div className="blockchain">
      <div className="content-container">
        {" "}
        <Row>
          <Col xs={24} lg={24}>
            <Col xs={24} lg={13} xl={12}>
              <div className="header-content">
                <Title level={1} className="header-content-text">
                  MUSIC MAKES YOU
                  <Text className="fade">
                    <TextTransition
                      text={TEXTS[index % TEXTS.length]}
                      springConfig={presets.wobbly}
                    />
                  </Text>
                </Title>
              </div>
            </Col>
            <Col xs={24}>
              <div className="content-icons-container">
                <img src={logo1} alt="logo" className="each-icon" />
                <img src={logo2} alt="logo" className="each-icon" />
                <img src={logo3} alt="logo" className="each-icon" />
                <img src={logo4} alt="logo" className="each-icon" />
                <img src={logo5} alt="logo" className="each-icon" />
              </div>
            </Col>
          </Col>
        </Row>
      </div>
      {/* Section 1 */}
      <div className="section">
        <section className="section1">
          <div className="section1-text">
            <Title>
              A Blockchain Ecosystem{" "}
              <span className="color-purple"> to Empower the </span> Music
              Industry
            </Title>
            <Title>
              Artists, fans, distributors and labels{" "}
              <span className="color-purple">
                in one single great, efficient and transparent channel.
              </span>{" "}
              Viveca <span className="color-purple"> is the innovation </span>{" "}
              that music lovers want.
            </Title>
          </div>
        </section>
        {/* section 2 */}
        <br />
        <br />
        <br />
        <br />
        <br />
        <section className="section2">
          <Row>
            <Col xs={24} md={24} lg={14}>
              <Title>With Viveca Token, you are the main character.</Title>
              <Text className="color-purple text-sm">
                The music industry has never been so dynamic, clear and
                transparent. Automated rules run the ecosystem.
              </Text>
              <br />
              <br />
              <br />
              <Button type="primary">
                Documentation
                <FaArrowAltCircleRight style={{ marginLeft: "10px" }} />
              </Button>
            </Col>

            <Col xs={24} md={12} lg={12}>
              <div className="card">
                <FaMusic className="ccg-icons" />
                <Title level={1}>Music</Title>
                <Text className="color-purple text-sm">
                Branded customer experience starts here. Capture the emotional power of music for business and put it to work at your location
                </Text>
              </div>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <div className="card">
                <FaTelegramPlane className="ccg-icons" />
                <Title>Messaging</Title>
                <Text className="color-purple text-sm">
                Capturing your brand voice is essential to your business. Transform audio messaging into a powerful part of your customer experience.
                </Text>
              </div>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <div className="card">
                <FaSlidersH className="ccg-icons" />

                <Title>Mix It</Title>
                <Text className="color-purple text-sm">
                Mix with targeted messages with the right music to communicate with your clients. Create a branded channel and impact decisions at the point-of-purchase.
                </Text>
              </div>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <div className="card">
                <BiCustomize className="ccg-icons" />
                <Title>Customize</Title>
                <Text className="color-purple text-sm">
                Our professional Music Designer helps you to develop your personalized soundtrack. Create a consistent experience in each of your locations around the globe.
                </Text>
              </div>
            </Col>
          </Row>
        </section>
        {/* section 3 */}
        <section className="section3">
          <Row>
            <Col xs={24} md={24} lg={19}>
              <Title>Viveca Media BlockChain</Title>
              <Text className="color-purple text-sm">
                Built using Cosmos-SDK, Viveca is a Delegated Proof of Stake
                (DPoS) blockchain. Some of the main features are:
              </Text>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <div className="card">
                <BiCustomize className="ccg-icons" />
                <Title>Tendermint Core</Title>
                <Text className="color-purple text-sm">
                Byzantine fault tolerant consensus engine that powers Cosmos Proof-of-Stake
                </Text>
              </div>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <div className="card">
                <BiCustomize className="ccg-icons" />
                <Title>Cosmos SDK</Title>
                <Text className="color-purple text-sm">
                Modular framework for building interoperable, application-specific blockchains
                </Text>
              </div>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <div className="card">
                <BiCustomize className="ccg-icons" />
                <Title>IBC Protocol</Title>
                <Text className="color-purple text-sm">
                TCP/IP-like messaging protocol for blockchains
                </Text>
              </div>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <div className="card">
                <BiCustomize className="ccg-icons" />
                <Title>IPFS</Title>
                <Text className="color-purple text-sm">
                The InterPlanetary File System (IPFS) is a peer-to-peer, distributed file system for connecting all devices within the same file system.
                </Text>
              </div>
            </Col>
          </Row>
        </section>
        {/* section 4 */}
        <section className="section4">
          <Row>
            <Col xs={24} lg={10}>
              <Title>Viveca World, you are the main character.</Title>
              <Text className="color-purple text-sm">
                The music industry has never been so dynamic, clear and
                transparent. Automated rules run the ecosystem.
              </Text>
              <br />
              <br />
              <br />
              <Button type="primary">
                Web player
                <FaPlay style={{ marginLeft: "10px" }} />
              </Button>
            </Col>
            <Col xs={24} lg={12}>
              <img src={PlayerImg} alt="player" className="player-img" />
            </Col>
          </Row>
        </section>
        {/* section 5 */}
        <section className="section5">
          <div className="stats-title">
            <Title>VivecaMedia</Title>
            <Text className="color-purple text-sm">
            Viveca is more than a crypto, it’s the ultimate currency for music.
            </Text>
          </div>
          <div className="stats">
            <Row justify="center" align="middle">
              <Col xs={24} lg={8}>
                <Title>
                  {" "}
                  No Intermediate{" "}
                  <BsPersonDash className="color-purple personDash" />{" "}
                </Title>
                <Title> Community Pool 2%</Title>
                <Title>
                  {" "}
                  <Text
                    className="color-purple"
                    style={{ marginRight: "10px" }}
                  >
                    {" "}
                    staking{" "}
                  </Text>{" "}
                  7-20%
                </Title>
              </Col>
              <Col xs={24} lg={8}>
                <div className="block-time">
                  <Title>
                    <span className="color-purple"> ~</span>5
                  </Title>
                  <Text className="color-purple" style={{ fontSize: "1.5em" }}>
                    seconds
                  </Text>
                  <br />
                  <Text
                    style={{
                      fontSize: "1.5em",
                      color: "#fff",
                      marginBottom: "20px",
                    }}
                  >
                    avg. Block Time
                  </Text>
                </div>
              </Col>
              <Col xs={0} md={0} lg={8}>
                <img src={waves} alt="" height="300px" width="auto" />
              </Col>
            </Row>
          </div>
        </section>
        {/* section 6 */}
        <section className="section6">
          <Title>Latest News</Title>
          <Row gutter={[30, 30]}>
            <Col xs={24} md={8} lg={6}>
              <img src={happy} alt="happy" height="260px" width="auto" />
              <Title level={2}>Mainnet Update — Unbonding feature ON!</Title>
            </Col>
            <Col xs={24} md={8} lg={6}>
              <img src={happy} alt="happy" height="260px" width="auto" />

              <Title level={2}>
                Gravity Dex — The Game Changer in DeFi 3.0
              </Title>
            </Col>
            <Col xs={24} md={8} lg={6}>
              <img src={happy} alt="happy" height="260px" width="auto" />

              <Title level={2}>Viveca Mainnet Update &amp; Statements</Title>
            </Col>
            <Col xs={24} md={8} lg={6}>
              <img src={happy} alt="happy" height="260px" width="auto" />

              <Title level={2}>Keplr has been integrated into Viveca</Title>
            </Col>
          </Row>
        </section>
        {/* section 7 */}
        <section className="section7">
          <Row>
            <Col xs={24} lg={12}>
              <Title>FAQ</Title>
              <Text className="text-sm">
                Blockchain Disruption in the Music Industry: Is it Happening
                Now?
              </Text>
            </Col>
            <Col xs={24} lg={12}>
              <Collapse
                collapsible="header"
                bordered={false}
                style={{
                  background: "#1D1D36",
                  borderRadius: "20px",
                  margin: "10px 0",
                }}
              >
                <Panel header="What problem does Viveca Blockchain solve? " key="1">
                  <Text style={{ color: "#fff", fontSize: "1.1em" }}>
                  The leading streaming music companies have been routinely accused of treating artists poorly through duplicitous contract structures and low payments. All of that has created a low-trust environment and confused artists and fans over whom to support.
                  </Text>
                </Panel>
              </Collapse>
              <Collapse
                collapsible="header"
                bordered={false}
                style={{
                  background: "#1D1D36",
                  borderRadius: "20px",
                  margin: "10px 0",
                }}
              >
                <Panel header="What’s the Viveca Blockchain goal? " key="1">
                  <Text style={{ color: "#fff", fontSize: "1.1em" }}>
                  Give back the power to the music! Our mission is to decentralize the music sector by simplifying the bureaucracy to offer artists a meritocratic, transparent, fast and intermediary-free earnings model while users gain a new way to listen to music and earn.
                  </Text>
                </Panel>
              </Collapse>
              <Collapse
                collapsible="header"
                bordered={false}
                style={{
                  background: "#1D1D36",
                  borderRadius: "20px",
                  margin: "10px 0",
                }}
              >
                <Panel header="Why should Artists join Viveca? " key="1">
                  <Text style={{ color: "#fff", fontSize: "1.1em" }}>
                  Artists can tokenize their music and restore a new equilibrium where art, real engagement, and passion wins. They have now royalties in real-time and new revenue streams since they own an economy based on tokens and NFTs.
                  </Text>
                </Panel>
              </Collapse>
            </Col>
          </Row>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Blockchain;
