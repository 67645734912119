import "./styles/style.css";
import Navbar from "./Components/Header";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Music from "./Pages/Music";
import Blockchain from "./Pages/Blockchain";

function App() {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/music" component={Music} />
        <Route path="/blockchain" component={Blockchain} />
      </Switch>
    </Router>
  );
}

export default App;
